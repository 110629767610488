import styles from './Underlay.module.css'
import PagePortfolio from "./page-portfolio/PagePortfolio";
import Background from "./background/Background";
import PageTeam from "./page-team/PageTeam";

export default function Underlay({page}) {
    return (
        <div className={styles.container}>
            <Background page={page}/>
            <PagePortfolio visible={page === 2}/>
            <PageTeam visible={page === 4}/>
        </div>
    )
}
