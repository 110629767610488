import {useGLTF} from "@react-three/drei";
import {useEffect, useMemo, useState} from "react";
import useIsMobile from "./useIsMobile";

export default function usePointsData() {

    const isMobile = useIsMobile()

    const folderName = isMobile ? './matter/mobile/' : './matter/desktop/'

    const gltfs = useGLTF([
        folderName + 'point-cloud-transformed.glb',
        folderName + 'svido-logo-transformed.glb',
        folderName + 'svido-text-transformed.glb',
        folderName + 'perspekt-transformed.glb',
        folderName + 'team-transformed.glb',
        folderName + 'svido-logo-bw-transformed.glb',
    ])

    const [data, setData] = useState(null);

    useEffect(() => {

        if (!gltfs) return;

        let positions = []
        let colors = []

        gltfs.forEach((gltf) => {
            const node = Object.values(gltf.nodes).find(node => node.name !== 'Scene')

            const position = node.geometry.attributes.position.array
            const color = node.geometry.attributes.color.array

            positions.push(position)
            colors.push(color)
        })

        positions = makeArraysEqualLength(positions, 3)
        colors = makeArraysEqualLength(colors, 4)

        positions = positions.map((position, index) => seededShuffleFloat32Array(position, index, 3))
        colors = colors.map((color, index) => seededShuffleFloat32Array(color, index, 4))

        const result = []

        for (let i = 0; i < positions.length; i++) {
            result[i] = {
                position: positions[i],
                color: colors[i]
            }
        }

        // for (let i = 0; i < result[4].color.length; i++) {
        //
        //     if (i % 4 === 0) {
        //         console.log()
        //
        //     }
        //
        // }

        setData(result)

    }, [gltfs])

    return data;
}

function seededRandom(seed) {
    let x = seed++ / Math.PI;
    return x - Math.floor(x);
}

function seededShuffleFloat32Array(array, seed, itemSize) {
    if (itemSize <= 0 || array.length % itemSize !== 0) {
        throw new Error("Invalid itemSize or array length.");
    }

    // Create a copy to avoid modifying the original array
    const shuffledArray = new Float32Array(array);

    // Fisher-Yates shuffle algorithm for items
    for (let i = shuffledArray.length - itemSize; i >= 0; i -= itemSize) {
        const rng = seededRandom(seed); // Generate a new random number for each iteration
        const j = Math.floor(rng * ((i / itemSize) + 1)) * itemSize;

        // Swap items
        const temp = shuffledArray.slice(i, i + itemSize);
        shuffledArray.set(shuffledArray.slice(j, j + itemSize), i);
        shuffledArray.set(temp, j);
    }

    return shuffledArray;
}

function makeArraysEqualLength(arrays, itemSize) {
    // Find the length of the longest Float32Array
    const maxLength = Math.max(...arrays.map(arr => arr.length));

    // Pad each Float32Array to the maximum length
    const paddedArrays = arrays.map(arr => {
        const paddedArray = new Float32Array(maxLength);

        for (let i = 0; i < arr.length; i++) {
            paddedArray[i] = arr[i]
        }

        // Fill the padded array with elements from the original array taken randomly
        for (let i = arr.length; i < maxLength; i+=itemSize) {

            const vertexIndex = i / itemSize;

            const random = Math.floor(seededRandom(vertexIndex) * arr.length / itemSize) * itemSize

            for (let j = 0; j < itemSize; j++) {
                paddedArray[i + j] = arr[random + j];
            }
        }

        return paddedArray;
    });

    return paddedArrays;
}
