import styles from './HudPageSpecific.module.css'
import BackgroundLogo from "./page-2/background-logo/BackgroundLogo";
import Page2 from "./page-2/Page2";
import Page1 from "./page-1/Page1";
import PageTeam from "./page-team/PageTeam";
import PageContact from "./page-contact/PageContact";
// import PagePortfolio from "./page-portfolio/PagePortfolio";
import PagePortfolio from "./page-portfolio-new/PagePortfolio";

export default function HudPageSpecific({page, setPage, isCameraLookingForward}) {

    return (
        <div className={styles.container}>


            <Page1 visible={page === 1} next={() => setPage(2)}/>
            <Page2 visible={page === 2} next={() => setPage(3)}/>
            <PagePortfolio visible={page === 3} page={page} next={() => setPage(4)} isCameraLookingForward={isCameraLookingForward}/>
            <PageTeam visible={page === 4} next={() => setPage(5)}/>
            <PageContact visible={page === 5}/>

        </div>
    )

}
