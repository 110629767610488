import styles from './PagePortfolio.module.css'
import {useEffect, useState} from "react";
import ImplementationsModal from "./implementations-modal/ImplementationsModal";
import ReadMoreModal from "./read-more-modal/ReadMoreModal";

export default function PagePortfolio({visible, page, next}) {

    const [isImplementationsModal, setIsImplementationsModal] = useState(false)
    const [isReadMeModal, setIsReadMeModal] = useState(false)

    useEffect(() => {
        setIsImplementationsModal(false)
    }, [page])

    return (
        <div className={styles.container}
             style={{
                 opacity: visible ? 1 : 0,
                 transitionDuration: '1s',
                 transitionDelay: visible ? '2s' : '0s',
                 pointerEvents: visible ? 'all' : 'none'
             }}
        >

        <img className={styles.background} src={'./images/screenshot.png'} alt={'background'}/>

            <div className={styles.titleContainer}
                 style={{
                     opacity: visible ? 1 : 0,
                     transform: `translateX(-50%) translateY(${visible ? '0' : '-50%'})`,
                     color: visible ? 'var(--color-ternary)' : 'var(--color-secondary)',
                     transitionDuration: '1s',
                     transitionDelay: visible ? '2s' : '0s'
                 }}
            >
                <div>for philharmonics, theatres and stadiums</div>
                <div onClick={() => setIsReadMeModal(true)}>read more</div>
            </div>

            <div className={styles.btnContainer}
                 style={{
                     opacity: visible ? 1 : 0,
                     transform: `translateX(-50%) translateY(${visible ? '0' : '-50%'})`,
                     color: visible ? 'var(--color-ternary)' : 'var(--color-secondary)',
                     transitionDuration: '1s',
                     transitionDelay: visible ? '2.5s' : '0s'
                 }}
            >
                <div onClick={() => setIsImplementationsModal(true)}>Implementations</div>
                <div onClick={next}>Svido Team</div>
            </div>


            <ImplementationsModal visible={isImplementationsModal} exit={() => setIsImplementationsModal(false)}/>

            <ReadMoreModal visible={isReadMeModal} exit={() => setIsReadMeModal(false)}/>
        </div>
    )
}
