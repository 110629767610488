import styles from './PageContact.module.css'
import useIsMobile from "../../../hooks/useIsMobile";
import {useRef} from "react";

export default function PageContact({visible}) {

    const isMobile = useIsMobile()

    const nameRef = useRef()
    const projectRef = useRef()

    const handleSendMail = () => {
        if (!nameRef.current.innerText || !projectRef.current.innerText) return;

        const emailAddress = 'szym.golab@gmail.com'

        const name = nameRef.current.innerText
        const project = projectRef.current.innerText

        const body = `Hello my name is ${name} and I would like to discuss about ${project}`

        window.open(`mailto:${emailAddress}?subject=${project}&body=${body}`);
    }

    return (
        <>
            <div className={styles.container}
                 style={{opacity: visible ? 1 : 0, transitionDuration: '1s', transitionDelay: visible ? '2s' : '0s'}}>

                {!isMobile &&
                    <div className={styles.header}>
                        Make 3D an advantage of yours
                    </div>
                }

                <div className={styles.grid} style={{pointerEvents: visible ? 'all' : 'none'}}>
                    <div className={styles.meetUs}>
                        <div className={styles.title}>Meet us</div>
                        <div style={{height: '120px'}}>
                            <div style={{marginBottom: '5px'}}>Svido sp. z o.o.</div>
                            <div style={{marginBottom: '5px'}}>szymon.golab@svido.app</div>
                            <div style={{marginBottom: '5px'}}>668 619 984</div>
                            <div style={{marginBottom: '5px'}}>daniel.kazarnowicz@svido.app</div>
                            <div style={{marginBottom: '5px'}}>692 634 428</div>
                        </div>
                    </div>
                    <div className={styles.pitchUs}>
                        <div className={styles.title}>Pitch us</div>
                        <div style={{height: '120px'}}>
                            hello,
                            <br/>
                            my name is&nbsp;
                            <div className={styles.input} style={{pointerEvents: visible ? 'all' : 'none'}} contentEditable={true} ref={nameRef}>your name</div>
                            &nbsp;and I would like to discuss about&nbsp;
                            <div className={styles.input} style={{pointerEvents: visible ? 'all' : 'none'}} contentEditable={true} ref={projectRef}>this project</div>.
                            &nbsp;
                            <div className={styles.sendBtn} style={{pointerEvents: visible ? 'all' : 'none'}} onClick={handleSendMail}>send</div>
                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}
