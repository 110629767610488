import styles from "./ReadMoreModal.module.css";

export default function ReadMoreModal({visible, exit}) {
    return (
        <div className={styles.container} style={{
            opacity: visible ? 1 : 0,
            transform: `translateY(${visible ? '0' : '5%'})`,
            pointerEvents: visible ? 'all' : 'none'
        }}>
            <div className={styles.list}>
                <a
                    href={'./download/PerspektVS-presentation.pdf'}
                    download="PerspektVS-presentation"
                    target="_blank"
                    rel="noreferrer"
                >
                    <div>Presentation
                        <span>.pdf</span>
                    </div>
                </a>

                <a
                    href={'./download/PerspektVS-specification.pdf'}
                    download="PerspektVS-specification"
                    target="_blank"
                    rel="noreferrer"
                >
                    <div>Specification
                        <span>.pdf</span>
                    </div>
                </a>


            </div>
            <div className={styles.btn} onClick={exit}>Read more</div>
        </div>
    )
}
