import styles from './Background.module.css'

export default function Background({page}) {



    return (
        <div className={styles.background} style={{
            transitionDuration: '2s',
            backgroundColor: page === 5 ? 'var(--color-tertiary)' : 'var(--color-background)'
        }}/>
    )
}
