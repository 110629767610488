import styles from "./PageTeam.module.css";

export default function PageTeam({visible}) {
    return (
        <>
            <div className={styles.backgroundText} style={{
                opacity: visible ? .1 : 0,
                transitionDuration: '4s',
                transitionDelay: visible ? '2s' : '0s'
            }}>
                SVIDO
            </div>
        </>
    )
}
