import {Canvas} from "@react-three/fiber"
import {Preload} from "@react-three/drei"
import usePointsData from "../hooks/usePointsData";
import {useEffect, useState} from "react";
import {usePrevious} from "@uidotdev/usehooks";
import Camera from "./camera/Camera";
import Objects from "./Objects";

export default function Scene({page, setPage, setIsCameraLookingForward}) {

    const previousPage = usePrevious(page);

    const pointsData = usePointsData()

    const [pointsStates, setPointsStates] = useState(null)

    useEffect(() => {

        if (!pointsData) return;

        setPointsStates([
            pointsData[previousPage ?? 0],
            pointsData[page]
        ])

    }, [page, pointsData])

    return (
        <Canvas shadows={true}
                gl={{
                    // logarithmicDepthBuffer: true,
                    // outputColorSpace: THREE.SRGBColorSpace,
                    // toneMapping: THREE.ReinhardToneMapping,
                    // sortObjects: true
                }}
                flat={true}
                camera={{
                    position: [0, 0, 1],
                    fov: 46.598,
                    near: 0.5,
                    far: 40,
                }}
                raycaster={{linePrecision: 0.001 }}
        >

            <Preload all/>

            <Objects pointsStates={pointsStates} page={page} setPage={setPage}/>

            <Camera page={page} setIsCameraLookingForward={setIsCameraLookingForward}/>

        </Canvas>
    )
}
