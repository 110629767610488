import Scene from "./scene/Scene";
import styles from './App.module.css'
import {useEffect, useState} from "react";
import Overlay from "./overlay/Overlay";
import Underlay from "./underlay/Underlay";
import {useIsModal} from "./hooks/useSelected";

function App() {

    const [page, setPage] = useState(0)



    useEffect(() => {
        setPage(1)
    }, [])

    const [isCameraLookingForward, setIsCameraLookingForward] = useState(true);

    return (
        <>
            <Underlay
                page={page}
            />

            <div className={styles.sceneContainer}>
                <Scene page={page} setPage={setPage} setIsCameraLookingForward={setIsCameraLookingForward}/>
            </div>

            <Overlay
                page={page}
                setPage={setPage}
                isCameraLookingForward={isCameraLookingForward}
            />
        </>
    )
}

export default App;
