import {useEffect, useState} from "react";
import styles from "./HudMobile.module.css";

export default function HudMobile({visible, page, setPage}) {

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(false)
    }, [page])

    return (
        <div style={{opacity: visible ? 1 : 0, transitionDuration: '1s', transitionDelay: '2s'}}>

            <div className={styles.title} style={{
                opacity: isOpen ? 0 : 1,
                color: page === 5? 'var(--color-background)' : 'var(--color-tertiary)',
                pointerEvents: isOpen ? 'all' : 'none'
            }} onClick={() => setPage(2)}>
                <img src={'./images/svido-logo.png'} alt={'svido'}/>
                <div>SVIDO</div>
            </div>

            <img className={styles.menuBtn}
                 style={{
                     opacity: visible && isOpen ? 0 : 1,
                     filter: `invert(${page === 5? 1 : 0})`
                 }}
                 src={'./icons/menu.png'}
                 alt={'menu'}
                 onClick={() => setIsOpen(true)}
            />

            <div className={styles.navMenu} style={{
                opacity: isOpen ? 1 : 0,
                transform: `translateX(-50%) translateY(${isOpen ? '0' : '-50%'})`,
                color: page === 5? 'var(--color-background)' : 'var(--color-tertiary)',
                pointerEvents: isOpen ? 'none' : 'all'
            }}>
                <div className={styles.navItem} onClick={() => setPage(3)}>Portfolio</div>
                <div className={styles.line} style={{backgroundColor: page === 5? 'var(--color-background)' : 'var(--color-tertiary)'}}/>
                <div className={styles.navItem} onClick={() => setPage(4)}>Team</div>
                <div className={styles.line} style={{backgroundColor: page === 5? 'var(--color-background)' : 'var(--color-tertiary)'}}/>
                <div className={styles.navItem} onClick={() => setPage(5)}>Contact</div>
            </div>
        </div>
    )
}
