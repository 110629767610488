import styles from "./ImplementationsModal.module.css";

export default function ImplementationsModal({visible, exit}) {
    return (
        <div className={styles.container} style={{
            opacity: visible ? 1 : 0,
            transform: `translateY(${visible ? '0' : '5%'})`,
            pointerEvents: visible ? 'all' : 'none'
        }}>
            <div className={styles.list}>
                <div onClick={() => {
                    window.open("https://sgolab.github.io/perspekt-ice/", '_blank')
                }}>Svido Concert Hall
                </div>
                <div onClick={() => {
                    window.open("https://sgolab.github.io/perspekt-legia/", '_blank')
                }}>Svido Football Stadium
                </div>

            </div>
            <div className={styles.btn} onClick={exit}>Read more</div>
        </div>
    )
}
