import styles from './Page1.module.css'

export default function Page1({visible, next}) {

    return (
        <>

            <div className={styles.btn}
                 style={{
                     opacity: visible ? 1 : 0,
                     color: visible ? "black" : "red",
                     transform: `translateX(-50%) translateY(${visible ? 0 : -50}%)`,
                     transitionDelay: visible ? '2s' : '0s',
                     transitionDuration: visible ? '1s' : '.2s',
                     boxShadow: visible ? '0px 0px 0px 1px rgba(0, 0, 0, 1)' : '0px 0px 0px 1px rgba(1, 0, 0, 1)',
                     pointerEvents: visible ? 'all' : 'none'
                 }}
                 onClick={next}
            >
                CHECK IT OUT
            </div>


        </>
    )
}
