import styles from './PagePortfolio.module.css'

export default function PagePortfolio({visible}) {
    return (
        <div className={styles.container}
             style={{
                 opacity: visible ? 1 : 0,
                 transitionDuration: '4s',
                 transitionDelay: visible ? '2s' : '0s'
             }}
        >
            <img className={styles.background}
                 style={{
                     transitionDuration: '16s',
                     transformOrigin: 'bottom right',
                     transform: `translateX(-75%) translateY(-50%) rotate(${visible ? '15deg' : '0'})`
                 }}
                 src={'./images/svido-logo.png'}
                 alt={''}
            />
        </div>
    )
}
