import BackgroundLogo from "./hud-page-specific/page-2/background-logo/BackgroundLogo";
import styles from './Overlay.module.css'
import HudGeneral from "./hud-general/hud-desktop/HudDesktop";
import HudPageSpecific from "./hud-page-specific/HudPageSpecific";
import Hud from "./hud-general/Hud";

export default function Overlay({page, setPage, isCameraLookingForward}) {
    return (
        <div className={styles.overlayContainer}>
            <HudPageSpecific page={page} setPage={setPage} isCameraLookingForward={isCameraLookingForward}/>
            <Hud visible={page > 1} page={page} setPage={setPage}/>

        </div>
    )
}
