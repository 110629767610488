import {CameraControls, OrbitControls} from "@react-three/drei";
import {useEffect, useRef} from "react";
import {useFrame, useThree} from "@react-three/fiber";
import * as THREE from 'three';
import {useIsModal} from "../../hooks/useSelected";

export default function Camera({page, setIsCameraLookingForward}) {

    const ref = useRef();

    const isModal = useIsModal(state => state.isModal)

    useEffect(() => {

        if (isModal) {
            ref.current.rotateAzimuthTo(Math.PI, true)
        }

    }, [isModal])

    useEffect(() => {
        ref.current.rotateAzimuthTo(0, true)
        ref.current.rotatePolarTo(Math.PI / 2, true)

        setTimeout(() => {
            ref.current.rotateAzimuthTo(0, true)
            ref.current.rotatePolarTo(Math.PI / 2, true)
        }, 2000)
    }, [page])

    const handleChange = () => {
        if (ref.current.azimuthAngle > (2 * Math.PI)) {
            ref.current.rotate(-Math.PI * 2, 0, false)
        }

        if (ref.current.azimuthAngle < (-2 * Math.PI)) {
            ref.current.rotate(Math.PI * 2, 0, false)
        }

        setIsCameraLookingForward(isLookingForward())
    }

    const handleMove = () => {

        setIsCameraLookingForward(isLookingForward())
    }

    const isLookingForward = () => {
        const horizontal = ref.current.azimuthAngle < (Math.PI / 4) && ref.current.azimuthAngle > (-Math.PI / 4)
        const vertical = (ref.current.polarAngle - Math.PI / 2) < (Math.PI / 4) && ref.current.polarAngle - Math.PI / 2 > (-Math.PI / 4)
        // const vertical = true

        return horizontal && vertical;
    }

    useEffect(() => {
        ref.current.addEventListener('rest', handleChange)
        ref.current.addEventListener('control', handleMove)
    }, [])

    return (
        <>
            <CameraControls ref={ref} enableZoom={false}
                            mouseButtons-right={null}
                            mouseButtons-middle={null}
                            mouseButtons-wheel={null}
                            touches-two={null}
                            touches-three={null}
                            makeDefault
                            draggingSmoothTime={0.1}
            />
        </>
    )
}
