import Matter from "./Matter";
import {useThree} from "@react-three/fiber";
import useIsMobile from "../hooks/useIsMobile";
import React from "react";

export default function Objects({page, setPage, pointsStates}) {

    const {viewport} = useThree()

    const isMobile = useIsMobile()

    const scale = viewport.width / (isMobile ? 5 : 12)

    return (
        <>
            <Matter pointsStates={pointsStates} scale={scale} page={page}/>
        </>
    )
}
