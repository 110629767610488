import useIsMobile from "../../hooks/useIsMobile";
import HudMobile from "./hud-mobile/HudMobile";
import HudDesktop from "./hud-desktop/HudDesktop";

export default function Hud({visible, page, setPage}) {

    const isMobile = useIsMobile()

    return (
        <>
            {isMobile &&
                <HudMobile visible={visible} page={page} setPage={setPage}/>
            }
            {!isMobile &&
                <HudDesktop visible={visible} page={page} setPage={setPage}/>
            }
        </>
    )
}
