import styles from './HudDesktop.module.css'

export default function HudDesktop({visible, page, setPage}) {

    return (
        <>
            <div className={styles.container} style={{
                opacity: visible ? 1 : 0,
                transitionDelay: '2s',
                transitionDuration: '0.5s',
                color: page === 5? 'var(--color-background)' : 'var(--color-tertiary)'
            }}>
                <div className={styles.title} onClick={() => setPage(2)}>
                    <img src={'./images/svido-logo.png'} alt={'svido'}/>
                    <div>SVIDO</div>
                </div>

                <div className={styles.navMenu}>
                    <div className={styles.navItem} onClick={() => setPage(3)}
                         style={{textShadow: "text-shadow: 4px 4px 0px rgba(0, 0, 0, 1);"}}>Portfolio
                    </div>
                    <div className={styles.line}
                         style={{backgroundColor: page === 5? 'var(--color-background)' : 'var(--color-tertiary)'}}/>
                    <div className={styles.navItem} onClick={() => setPage(4)}>Team</div>
                    <div className={styles.line}
                         style={{backgroundColor: page === 5? 'var(--color-background)' : 'var(--color-tertiary)'}}/>
                    <div className={styles.navItem} onClick={() => setPage(5)}>Contact</div>
                </div>

            </div>
        </>
    )
}
