import {useEffect, useMemo, useRef} from "react";
import {useFrame, useThree} from "@react-three/fiber";
import * as THREE from 'three'
import {useGLTF} from "@react-three/drei"
import useIsMobile from "../hooks/useIsMobile";

export default function Matter({page, pointsStates, scale}) {

    const ref = useRef()

    const startTime = useRef(Date.now())

    useEffect(() => {

        if (!pointsStates) return;

        startTime.current = Date.now()

        const geometry = ref.current.geometry;

        geometry.getAttribute('positionFrom').array = pointsStates[0].position
        geometry.getAttribute('positionTo').array = pointsStates[1].position

        geometry.getAttribute('colorFrom').array = pointsStates[0].color
        geometry.getAttribute('colorTo').array = pointsStates[1].color

        geometry.getAttribute('positionFrom').needsUpdate = true
        geometry.getAttribute('positionTo').needsUpdate = true
        geometry.getAttribute('colorFrom').needsUpdate = true
        geometry.getAttribute('colorTo').needsUpdate = true

        // let temp = geometry.getAttribute('positionFrom')
        // geometry.setAttribute( 'positionFrom', geometry.getAttribute('positionTo'));
        // geometry.setAttribute( 'positionTo', temp);
        //
        // temp = geometry.getAttribute('colorFrom')
        // geometry.setAttribute( 'colorFrom', geometry.getAttribute('colorTo'));
        // geometry.setAttribute( 'colorTo', temp);

    }, [pointsStates])

    const material = useMemo(() => {
        const m = new THREE.ShaderMaterial({

            uniforms: {
                time: {value: 0.0},
                pointSize: {value: 3.0},
            },

            vertexShader: `
            
                uniform float time;
                uniform float pointSize;   
                
                attribute vec3 positionFrom; 
                attribute vec3 positionTo;
                 
                attribute vec4 colorFrom;
                attribute vec4 colorTo;
                
                varying float vSeededIndex;
                
                varying vec4 vColorFrom;
                varying vec4 vColorTo;
                
                varying float progress;                                       
               
              
                float rand(int seed) {
                    return fract(sin(float(seed)) * 43758.5453);
                }
                             
                float easeInOutQuint(float x) {
                    if (x < 0.5) {
                        return 16.0 * x * x * x * x * x;
                    } else {
                        float t = -2.0 * x + 2.0;
                        return 1.0 - pow(t, 5.0) / 2.0;
                    }
                }
                
                float quadraticBezier(float valueA, float valueB, float factor, float control) {
                    float oneMinusT = 1.0 - factor;
                
                    float newX = oneMinusT * oneMinusT * valueA + 2.0 * oneMinusT * factor * control + factor * factor * valueB;
                
                    return newX;
                }

                void main() {
                
                  vSeededIndex = rand(gl_VertexID);
                
                  vColorFrom = colorFrom;
                  vColorTo = colorTo;                            
                                   
                  float ANIMATION_DURATION = 3000.0;
                  float MAX_DELAY_MULTIPLIER = 0.2;
                                   
                  float pointAnimStartDelay = vSeededIndex * ANIMATION_DURATION * MAX_DELAY_MULTIPLIER;
                  // float pointAnimStartDelay = abs(positionTo.y) * ANIMATION_DURATION * MAX_DELAY_MULTIPLIER;
                                   
                  float progressLinear = max(min((time - pointAnimStartDelay) / (ANIMATION_DURATION - (ANIMATION_DURATION * MAX_DELAY_MULTIPLIER)), 1.0), 0.0);
                  progress = easeInOutQuint(progressLinear);                                  
                  
                  // vec3 positionCurrent = vec3(
                  //   quadraticBezier(positionFrom.x, positionTo.x, progress, (rand(gl_VertexID) - 0.5) * 0.2),
                  //   quadraticBezier(positionFrom.y, positionTo.y, progress, (rand(gl_VertexID + 1) - 0.5) * 0.2),
                  //   quadraticBezier(positionFrom.z, positionTo.z, progress, (rand(gl_VertexID + 2) - 0.5) * 0.2)                   
                  // );      

                  vec3 positionCurrent = vec3(
                    mix(positionFrom.x, positionTo.x, progress) + (sin(progress * 2.0 * 3.1415) * rand(gl_VertexID)) * 0.5,                   
                    mix(positionFrom.y, positionTo.y, progress),                   
                    mix(positionFrom.z, positionTo.z, progress) + (sin(progress * 2.0 * 3.1415) * rand(gl_VertexID)) * 0.5                 
                  );        

                  // vec3 positionCurrent = vec3(
                  //   quadraticBezier(positionFrom.x, positionTo.x, progress, (rand(gl_VertexID) - 0.5) * 1.0) + (sin(progress * 2.0 * 3.1415) * rand(gl_VertexID)) / 2.0,                   
                  //   quadraticBezier(positionFrom.y, positionTo.y, progress, (rand(gl_VertexID) - 0.5) * 1.0),                   
                  //   quadraticBezier(positionFrom.z, positionTo.z, progress, (rand(gl_VertexID) - 0.5) * 1.0)  + (sin(progress * 2.0 * 3.1415) * rand(gl_VertexID)) / 2.0                 
                  // );   
                                   
                  gl_PointSize = pointSize;                 
                  gl_Position = projectionMatrix * modelViewMatrix * vec4(positionCurrent.x, positionCurrent.y, positionCurrent.z, 1.0);
                }
            `,
            fragmentShader: `           
                      
                varying vec4 vColorFrom;
                varying vec4 vColorTo;
                
                varying float vSeededIndex;
                
                varying float progress;
                
                void main() {               
              
                    vec4 mixed = mix(vColorFrom, vColorTo, progress);
              
                    vec4 color = mixed;
                    
                    float progressCenter = (((abs(progress * 4.0 - 2.0) / 2.0) - 0.5) * -1.0) + 0.5;
                    progressCenter = progressCenter * 0.5;
                    vec4 red = vec4(169.0/255.0, 13.0/255.0, 21.0/255.0, 1.0);       
                    
                    vec4 centered = mix(mixed, red, progressCenter);        
              
                    // gl_FragColor = vec4(color.x, color.y, color.z, color.w);                   
                    gl_FragColor = centered;                   
                }
            `
        });

        m.transparent = true;
        m.alphaTest = 0.1;
        // m.depthWrite = false;
        m.depthTest = true;

        return m;
    }, [])

    useEffect(() => {

        if (page !== 1) {
            setTimeout(() => {
                material.depthTest = page === 1
            }, 2000)
        }


    }, [page])

    useFrame(() => {

        const ANIMATION_DURATION = 3000

        // material.uniforms.time.value = Math.max(Math.min((Date.now() - startTime.current) / ANIMATION_DURATION, 1), 0)
        material.uniforms.time.value = Date.now() - startTime.current;
        material.uniforms.pointSize.value = isMobile ? 1.8 : 3.0;

    })

    const isMobile = useIsMobile()

    return (
        <>
            {pointsStates &&
                <points ref={ref} position={[0, 0, 0]} rotation={[0, 0, 0]} material={material}
                        scale={[scale, scale, scale]}>
                    <bufferGeometry>
                        <bufferAttribute
                            attach='attributes-position'
                            count={pointsStates[0].position.length / 3}
                            itemSize={3}
                            array={pointsStates[0].position}
                        />
                        <bufferAttribute
                            attach='attributes-positionFrom'
                            count={pointsStates[0].position.length / 3}
                            itemSize={3}
                            array={pointsStates[0].position}
                        />
                        <bufferAttribute
                            attach='attributes-positionTo'
                            count={pointsStates[1].position.length / 3}
                            itemSize={3}
                            array={pointsStates[1].position}
                        />
                        <bufferAttribute
                            attach='attributes-colorFrom'
                            count={pointsStates[0].color.length / 4}
                            itemSize={4}
                            array={pointsStates[0].color}
                        />
                        <bufferAttribute
                            attach='attributes-colorTo'
                            count={pointsStates[1].color.length / 4}
                            itemSize={4}
                            array={pointsStates[1].color}
                        />
                    </bufferGeometry>
                </points>
            }
        </>
    )
}


function seededRandom(seed) {
    let state = seed;
    return function () {
        const x = Math.sin(state++) * 10000;
        return x - Math.floor(x);
    };
}

// Seeded Fisher-Yates shuffle for Float32Array
function seededShuffleFloat32Array(array, seed, itemSize) {
    if (itemSize <= 0 || array.length % itemSize !== 0) {
        throw new Error("Invalid itemSize or array length.");
    }

    const rng = seededRandom(seed);

    // Create a copy to avoid modifying the original array
    const shuffledArray = new Float32Array(array);

    // Fisher-Yates shuffle algorithm for items
    for (let i = shuffledArray.length - itemSize; i >= 0; i -= itemSize) {
        const j = Math.floor(rng() * ((i / itemSize) + 1)) * itemSize;

        // Swap items
        const temp = shuffledArray.slice(i, i + itemSize);
        shuffledArray.set(shuffledArray.slice(j, j + itemSize), i);
        shuffledArray.set(temp, j);
    }

    return shuffledArray;
}

function makeArraysEqualLength(arr1, arr2) {
    const length1 = arr1.length;
    const length2 = arr2.length;

    if (length1 === length2) {
        return [arr1, arr2];
    }

    const longerArray = length1 > length2 ? arr1 : arr2;
    const shorterArray = length1 > length2 ? arr2 : arr1;

    const diff = Math.abs(length1 - length2);

    // Create a new array with the same elements as the shorter array
    const extendedArray = new Float32Array(shorterArray);
    for (let i = 0; i < diff; i++) {
        const randomIndex = Math.floor(Math.random() * shorterArray.length);
        extendedArray[length1 > length2 ? length2 + i : length1 + i] = shorterArray[randomIndex];
    }

    // Return the modified arrays
    return [new Float32Array(extendedArray), new Float32Array(longerArray)];
}
