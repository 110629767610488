import styles from './PageTeam.module.css'
import useIsMobile from "../../../hooks/useIsMobile";

export default function PageTeam({visible, next}) {

    const isMobile = useIsMobile()

    return (
        <>
            <div className={styles.container}
                 style={{
                     left: '25%',
                     opacity: visible ? 1 : 0,
                     color: visible ? "black" : "red",
                     transform: `translateX(-50%) translateY(${visible ? 0 : -100}%)`,
                     transitionDelay: visible ? '2s' : '0s'
                 }}>
                <div className={styles.name}>
                    Szymon Gołąb
                </div>
                <div className={styles.title}>
                    Svido Co-founder
                </div>
                <div className={styles.title}>
                    3D Graphic Designer
                </div>
            </div>

            <div className={styles.container}
                 style={{
                     right: '25%',
                     opacity: visible ? 1 : 0,
                     color: visible ? "black" : "red",
                     transform: `translateX(50%) translateY(${visible ? 0 : -100}%)`,
                     transitionDelay: visible ? '2s' : '0s'
                 }}>
                <div className={styles.name}>
                    Daniel Kazarnowicz
                </div>
                <div className={styles.title}>
                    Svido Co-founder
                </div>
                <div className={styles.title}>
                    Fullstack C# Developer
                </div>
            </div>

            <div className={styles.btn}
                 style={{
                     opacity: visible ? 1 : 0,
                     color: visible ? "var(--color-tertiary)" : "var(--color-primary)",
                     transform: `translateX(-50%) translateY(${visible ? 0 : -50}%)`,
                     transitionDelay: visible ? '2.5s' : '0s',
                     transitionDuration: visible ? '1s' : '.2s',
                     pointerEvents: visible ? 'all' : 'none'
                 }}
                 onClick={next}
            >
                Contact
            </div>
        </>
    )
}
