import BackgroundLogo from "./background-logo/BackgroundLogo";
import styles from './Page2.module.css'

export default function Page2({visible, next}) {

    return (
        <>
            <div className={styles.subtitle}
                 style={{
                     opacity: visible ? 1 : 0,
                     color: visible ? "var(--color-tertiary)" : "var(--color-primary)",
                     transform: `translateX(-50%) translateY(${visible ? 0 : -50}%)`,
                     transitionDelay: visible ? '2s' : '0s',
                     transitionDuration: '1s'
                 }}>
                SOFTWARE DEVELOPMENT
            </div>

            <div className={styles.btn}
                 style={{
                     opacity: visible ? 1 : 0,
                     color: visible ? "var(--color-tertiary)" : "var(--color-primary)",
                     transform: `translateX(-50%) translateY(${visible ? 0 : -50}%)`,
                     transitionDelay: visible ? '2.5s' : '0s',
                     transitionDuration: '1s',
                     pointerEvents: visible ? 'all' : 'none'
                 }}
                 onClick={next}
            >
                Our projects
            </div>

        </>
    )
}
